<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>

        <main class="space-y-10">
          <section class="bg-white rounded-2xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">綠界電子發票</h1>
              <vs-switch color="success" v-model="ecpayActive" :disabled="!(ecpay_sid && ecpay_invoice_hashkey && ecpay_invoice_hashiv)">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>
            <div class="w-full">
              <h2 class="title">商店代號</h2>
              <div class="relative">
                <ValidationProvider name="商店代號 " :rules="`${ecpayActive ? 'required|max:20' : ''}`" v-slot="{ errors }">
                  <vs-input v-model="ecpay_sid" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>

            <div class="flex justify-around space-x-4">
              <div class="w-full">
                <h2 class="title">電子發票HashKey</h2>
                <div class="relative">
                  <ValidationProvider name="電子發票HashKey" :rules="`${ecpayActive ? 'required|max:40' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="ecpay_invoice_hashkey" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full">
                <h2 class="title">電子發票HashIV</h2>
                <div class="relative">
                  <ValidationProvider name="電子發票HashIV" :rules="`${ecpayActive ? 'required|max:40' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="ecpay_invoice_hashiv" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </section>

          <section class="bg-white rounded-2xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">ezPay 電子發票</h1>
              <vs-switch color="success" v-model="ezpayActive" :disabled="!(ezpay_sid && ezpay_invoice_hashkey && ezpay_invoice_hashiv)">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>

            <div class="w-full">
              <div class="flex space-x-2">
                <h2 class="title">啟用 ezPay 電子發票</h2>
                <div class="w-10 -mt-1.5"></div>
              </div>
            </div>
            <div class="w-full">
              <h2 class="title">商店代號</h2>
              <div class="relative">
                <ValidationProvider name="商店代號" :rules="`${ezpayActive ? 'required|max:20' : ''}`" v-slot="{ errors }">
                  <vs-input v-model="ezpay_sid" />
                  <errorDiv>{{ errors[0] }}</errorDiv>
                </ValidationProvider>
              </div>
            </div>
            <div class="flex justify-around space-x-4">
              <div class="w-full">
                <h2 class="title">金流HashKey</h2>
                <div class="relative">
                  <ValidationProvider name="金流HashKey" :rules="`${ezpayActive ? 'required|max:40' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="ezpay_invoice_hashkey" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full">
                <h2 class="title">金流HashIV</h2>
                <div class="relative">
                  <ValidationProvider name="金流HashIV" :rules="`${ezpayActive ? 'required|max:40' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="ezpay_invoice_hashiv" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </section>

          <hr />
          <div class="flex justify-end">
            <vs-button size="large" ref="saveData" :disabled="invalid" @click="saveData(invalid)"
              ><span class="font-medium">儲存</span></vs-button
            >
          </div>
        </main>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      test: '',
      ecpayActive: false,
      ezpayActive: false,
      ecpay_sid: '',
      ecpay_invoice_hashkey: '',
      ecpay_invoice_hashiv: '',
      ezpay_sid: '',
      ezpay_invoice_hashkey: '',
      ezpay_invoice_hashiv: '',
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$axios({
        url: 'front/set/app/getInvoiceData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data

          this.ecpay_invoice = data.ecpay_invoice
          this.ecpay_sid = data.ecpay_sid
          this.ecpay_invoice_hashkey = data.ecpay_invoice_hashkey
          this.ecpay_invoice_hashiv = data.ecpay_invoice_hashiv

          this.ezpay_invoice = data.ezpay_invoice
          this.ezpay_sid = data.ezpay_sid
          this.ezpay_invoice_hashkey = data.ezpay_invoice_hashkey
          this.ezpay_invoice_hashiv = data.ezpay_invoice_hashiv

          data.ecpay_invoice === 1 ? (this.ecpayActive = true) : (this.ecpayActive = false)
          data.ezpay_invoice === 1 ? (this.ezpayActive = true) : (this.ezpayActive = false)
        } else {
          console.error(res.data.Message)
        }
      })
    },
    saveData(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveData,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let data = {
        lang: 0,
        ecpay_invoice: this.ecpay_invoice,
        ecpay_sid: this.ecpay_sid,
        ecpay_invoice_hashkey: this.ecpay_invoice_hashkey,
        ecpay_invoice_hashiv: this.ecpay_invoice_hashiv,
        ezpay_invoice: this.ezpay_invoice,
        ezpay_sid: this.ezpay_sid,
        ezpay_invoice_hashkey: this.ezpay_invoice_hashkey,
        ezpay_invoice_hashiv: this.ezpay_invoice_hashiv,
      }

      this.$axios({
        url: 'front/set/app/uInvoiceData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadData()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
  },
  computed: {
    ecpay_invoice: {
      get() {
        return this.ecpayActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
    ezpay_invoice: {
      get() {
        return this.ezpayActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
  },
  watch: {
    ecpay_sid: function () {
      if (this.ecpay_sid === '' && this.ecpay_invoice_hashkey === '' && this.ecpay_invoice_hashiv === '') {
        this.ecpayActive = false
      }
    },
    ecpay_invoice_hashkey: function () {
      if (this.ecpay_sid === '' && this.ecpay_invoice_hashkey === '' && this.ecpay_invoice_hashiv === '') {
        this.ecpayActive = false
      }
    },
    ecpay_invoice_hashiv: function () {
      if (this.ecpay_sid === '' && this.ecpay_invoice_hashkey === '' && this.ecpay_invoice_hashiv === '') {
        this.ecpayActive = false
      }
    },
    ezpay_sid: function () {
      if (this.ezpay_sid === '' && this.ezpay_invoice_hashkey === '' && this.ezpay_invoice_hashiv === '') {
        this.ezpayActive = false
      }
    },
    ezpay_invoice_hashkey: function () {
      if (this.ezpay_sid === '' && this.ezpay_invoice_hashkey === '' && this.ezpay_invoice_hashiv === '') {
        this.ezpayActive = false
      }
    },
    ezpay_invoice_hashiv: function () {
      if (this.ezpay_sid === '' && this.ezpay_invoice_hashkey === '' && this.ezpay_invoice_hashiv === '') {
        this.ezpayActive = false
      }
    },
    ecpayActive: function (newval, oldval) {
      if (newval === true) {
        this.ezpayActive = false
      }
    },
    ezpayActive: function (newval, oldval) {
      if (newval === true) {
        this.ecpayActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}
</style>
