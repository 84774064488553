var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"router-view-container"},[_c('div',{staticClass:"flex items-center space-x-4 mb-5"},[_c('vs-button',{staticClass:"flex-shrink-0",attrs:{"border":"","size":"large"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg-icon',{attrs:{"icon-class":"arrow_left","className":"primary"}})],1),_c('h1',{staticClass:"page_title"},[_vm._v(_vm._s(_vm.$route.meta.title))])],1),_c('main',{staticClass:"space-y-10"},[_c('section',{staticClass:"bg-white rounded-2xl p-4 space-y-4"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('h1',{staticClass:"bigTitle"},[_vm._v("綠界電子發票")]),_c('vs-switch',{attrs:{"color":"success","disabled":!(_vm.ecpay_sid && _vm.ecpay_invoice_hashkey && _vm.ecpay_invoice_hashiv)},scopedSlots:_vm._u([{key:"off",fn:function(){return [_c('span',{staticClass:"text-sm"},[_vm._v("已停用")])]},proxy:true},{key:"on",fn:function(){return [_c('span',{staticClass:"text-sm"},[_vm._v("啟用中")])]},proxy:true}],null,true),model:{value:(_vm.ecpayActive),callback:function ($$v) {_vm.ecpayActive=$$v},expression:"ecpayActive"}})],1),_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v("商店代號")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"商店代號 ","rules":("" + (_vm.ecpayActive ? 'required|max:20' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.ecpay_sid),callback:function ($$v) {_vm.ecpay_sid=$$v},expression:"ecpay_sid"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex justify-around space-x-4"},[_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v("電子發票HashKey")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"電子發票HashKey","rules":("" + (_vm.ecpayActive ? 'required|max:40' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.ecpay_invoice_hashkey),callback:function ($$v) {_vm.ecpay_invoice_hashkey=$$v},expression:"ecpay_invoice_hashkey"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v("電子發票HashIV")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"電子發票HashIV","rules":("" + (_vm.ecpayActive ? 'required|max:40' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.ecpay_invoice_hashiv),callback:function ($$v) {_vm.ecpay_invoice_hashiv=$$v},expression:"ecpay_invoice_hashiv"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_c('section',{staticClass:"bg-white rounded-2xl p-4 space-y-4"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('h1',{staticClass:"bigTitle"},[_vm._v("ezPay 電子發票")]),_c('vs-switch',{attrs:{"color":"success","disabled":!(_vm.ezpay_sid && _vm.ezpay_invoice_hashkey && _vm.ezpay_invoice_hashiv)},scopedSlots:_vm._u([{key:"off",fn:function(){return [_c('span',{staticClass:"text-sm"},[_vm._v("已停用")])]},proxy:true},{key:"on",fn:function(){return [_c('span',{staticClass:"text-sm"},[_vm._v("啟用中")])]},proxy:true}],null,true),model:{value:(_vm.ezpayActive),callback:function ($$v) {_vm.ezpayActive=$$v},expression:"ezpayActive"}})],1),_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex space-x-2"},[_c('h2',{staticClass:"title"},[_vm._v("啟用 ezPay 電子發票")]),_c('div',{staticClass:"w-10 -mt-1.5"})])]),_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v("商店代號")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"商店代號","rules":("" + (_vm.ezpayActive ? 'required|max:20' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.ezpay_sid),callback:function ($$v) {_vm.ezpay_sid=$$v},expression:"ezpay_sid"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"flex justify-around space-x-4"},[_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v("金流HashKey")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"金流HashKey","rules":("" + (_vm.ezpayActive ? 'required|max:40' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.ezpay_invoice_hashkey),callback:function ($$v) {_vm.ezpay_invoice_hashkey=$$v},expression:"ezpay_invoice_hashkey"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"title"},[_vm._v("金流HashIV")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"金流HashIV","rules":("" + (_vm.ezpayActive ? 'required|max:40' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.ezpay_invoice_hashiv),callback:function ($$v) {_vm.ezpay_invoice_hashiv=$$v},expression:"ezpay_invoice_hashiv"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_c('hr'),_c('div',{staticClass:"flex justify-end"},[_c('vs-button',{ref:"saveData",attrs:{"size":"large","disabled":invalid},on:{"click":function($event){return _vm.saveData(invalid)}}},[_c('span',{staticClass:"font-medium"},[_vm._v("儲存")])])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }